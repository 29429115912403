import { useGlobalStore } from '~/stores'
import groupBy from 'lodash-es/groupBy'
import type { FooterMenu } from "~/shared/types/digest/digest"

export function useAsideMenu() {
  const globalStore = useGlobalStore()

  const menuTypes = globalStore.digest.asideMenuTypes
  const linkGroup =  groupBy(globalStore.digest.asideMenu, 'type')

  const createDivider = (): { type: 'divider' } => {
    return {
      type: 'divider'
    }
  }

  type TMenu = (FooterMenu | ReturnType<typeof createDivider>)[]

  const menu = ref<TMenu[]>([])

  if (menuTypes?.length) {
    for (const menuType of menuTypes) {
      const links: FooterMenu[] = []

      for (const linkItem of linkGroup[menuType.key].sort((a, b) => a.sort - b.sort)) {
        links.push(linkItem)
      }

      menu.value.push([
        ...links,
        createDivider()
      ])
    }
  }

  return {
    menu,
  }
}
