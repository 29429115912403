import { onMounted, onUnmounted, nextTick, computed } from 'vue'

/*
данный композишн возвращает стили для плавающего блока-списка, которому считается высота и top
по динамичной высоте другого блока, ниже которого он всегда должен располагаться
то есть heigth = 100vh - высота связ блока, top = высота связ блока
*/
export default function useRelatedFloatingBlock(heightRelatedBlock: any, idRelatedBlock: string) {
  const styles = computed(() => {
    return {
      top: heightRelatedBlock.value + 'rem',
      height: `calc(100% - ${heightRelatedBlock.value}rem)`,
    }
  })

  const stylesValues = computed(() => {
    return {
      top: heightRelatedBlock.value,
      height: heightRelatedBlock.value,
    }
  })

  return {
    styles,
    stylesValues,
  }
}
