<template>
  <nav
    v-show="width > 1199 || visibleLeftMenu"
    class="left-menu"
    :style="styles"
    data-testid="element_000039"
    itemscope
    itemtype="http://schema.org/SiteNavigationElement"
    @scroll.capture.stop
  >
    <div class="left-menu__before" @click="changeVisibleLeftMenu(false)" />

    <div data-testid="element_000305">
      <ul v-for="(group, groupIndex) in menu" :key="group.length" data-testid="element_000040">
        <template
          v-for="item in group"
          :key="item.link"
        >
          <li
            v-if="typeof item.type === 'number' && applyRule(item)"
            :class="isActiveLink(item) && 'active'"
            data-testid="element_000306"
            @click="changeVisibleLeftMenu(false)"
          >
            <ui-link :link="{ to: item.link }" :rel="item.rel" :itemprop="item.link">
              <ui-icon v-if="iconName(item)" :name="iconName(item)" :size="20" filled />
              <span data-testid="element_000308"> {{ item.label }} </span>
            </ui-link>
          </li>

          <li
            v-if="typeof item.type === 'string'"
            :class="{
              'hidden': groupIndex >= menu.length - 1
            }"
            data-testid="element_000041"
          />
        </template>
      </ul>
    </div>

    <div data-testid="element_000326" class="left-menu-bg" @click="changeVisibleLeftMenu(false)" />
  </nav>
</template>

<script lang="ts" setup>
import type { FooterMenu } from "~/shared/types/digest/digest"
import type { TUiIconNames } from "#build/types/ui-icon"
import { useConditionStore } from "~/stores/condition"

const router = useRouter()
const { menu } = useAsideMenu()

const { width } = useSSRWindowSize()
const conditionStore = useConditionStore()
const heightHeader = computed(() => {
  return width.value >= 1200 ? 66 : 56
})
const { styles } = useRelatedFloatingBlock(heightHeader, "header")

const { applyRule } = useCanDisplayItem()

const {
  visibleLeftMenu,
  changeVisibleLeftMenu,
} = useLayoutDefault()

watch(width, () => {
  if (!visibleLeftMenu.value) {
   return false
  }
  if (width.value >= 1200) {
    conditionStore.activeBody(false)
  } else {
    conditionStore.activeBody(true)
  }
})
const isActiveLink = computed(() => {
  return (item: FooterMenu) => {
    let link = item.link
    if (link === "//") {
      link = "/"
    }

    const resolvedRoute = router.resolve(link)

    return resolvedRoute.name === router.currentRoute.value.name &&
      router.currentRoute.value.path.includes(resolvedRoute.path)
  }
})

const iconName = computed(() => {
  return (item: FooterMenu): TUiIconNames => {
    if (item.link === "/camgirls/") {
      if (isActiveLink.value(item)) {
        return "Menu/WebcamActive"
      }
      return "Menu/Webcam"
    }
    return item?.icon || ""
  }
})

const filledIcon = computed(() => {
  return (item: FooterMenu) => {
    if (item.link === "/camgirls/") {
      return true
    }
    return false
  }
})
</script>
