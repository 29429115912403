<template>
  <ui-link
    v-if="isShow"
    :link="{ to: headerBar.buttonUrl, inNewTab: headerBar.openLinkInNewTab }"
    :style="customStyle"
    class="app-header-bar"
  >
    <div v-if="!timerTimestamp" class="app-header-bar__banner-timer" data-testid="elem_vrp_001619">
      {{ headerBar.title }}
    </div>
    <client-only v-else>
      <countdown
        ref="timerElement"
        :time="timerTimestamp"
        :key="timerKey"
        @finish="headerBar.timeForTrigger = ''"
        format="DD:HH:mm:ss"
        class="app-header-bar__banner-timer"
      >
        <template #="{ resolved }">
          <span class="app-header-bar__countdown-item mr2-2">{{ headerBar.title }}:</span>
          <span class="app-header-bar__countdown-item" data-testid="elem_vrp_001618">{{ resolved.DD }}</span> :
          <span class="app-header-bar__countdown-item" data-testid="elem_vrp_001620">{{ resolved.HH }}</span> :
          <span class="app-header-bar__countdown-item" data-testid="elem_vrp_001621">{{ resolved.mm }}</span> :
          <span class="app-header-bar__countdown-item" data-testid="elem_vrp_001622">{{ resolved.ss }}</span>
        </template>
      </countdown>
    </client-only>
  </ui-link>
</template>

<script setup lang="ts">
import Countdown from "vue3-countdown"
import apiContentHeaderBar, { type IApiContentHeaderBarItem } from "~/api/content/show-header"
import { useUserStore } from "~/stores/user"

const userStore = useUserStore()

const router = useRouter()
const conditionStore = useConditionStore()
const { layoutIsShowHeaderBar:isShow } = storeToRefs(conditionStore)

const timerKey = ref(router.currentRoute.value.fullPath)
const headerBar = ref<IApiContentHeaderBarItem>({})

const timerTimestamp = computed(() => {
  if (headerBar.value.timeForTrigger) {
    const addedSeconds = Number(headerBar.value.timeForTrigger.slice(0,2)) * 60 + Number(headerBar.value.timeForTrigger.slice(3,5))
    return addedSeconds * 1000
  }
  return 0
})

const customStyle = computed(() => {
  if (!(headerBar.value.gradient1 && headerBar.value.gradient2)) {
    return {}
  }
  return {
    background: `linear-gradient(90deg, ${headerBar.value.gradient1} 0%, ${headerBar.value.gradient2} 100%)`
  }
})

const updateContentHeaderBarQuery = async () => {
  const payload = () => {
    if(userStore.isStatus.expired) {
      return {
        active: true,
        showToExpired: true,
        showAllPages: true,
      }
    }
    return {
      showToGuest: userStore.isRole.guest,
      showToInactive: userStore.isStatus.inactive,
      showToMembers: userStore.isRole.user,
      affiliateId: userStore.getAffiliateId(),
      shownValuePopUps: [],
      url: router.currentRoute.value.fullPath,
    }
  }

  const contentHeaderBarResult = await apiContentHeaderBar(payload())

  const headerBarAlwaysShow = contentHeaderBarResult ? Object.values(contentHeaderBarResult).find(obj => obj.alwaysShow === true) : false
  if(contentHeaderBarResult?.$http.status === 200) {
    conditionStore.setIsShowHeaderBar(true)
    if (headerBarAlwaysShow) {
      headerBar.value = headerBarAlwaysShow
    } else {
      headerBar.value = contentHeaderBarResult[0]
    }
  } else {
    conditionStore.setIsShowHeaderBar(false)
  }
}

onMounted(updateContentHeaderBarQuery)

watch(router.currentRoute, () => {
  updateContentHeaderBarQuery()
  timerKey.value = router.currentRoute.value.fullPath
})

</script>

<style scoped lang="scss">
.app-header-bar {
  padding: 14rem 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: map-get($grayPalette, 'color_ff');
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 600;
  background: map-get($gradientsPalette, variant_0);
  min-height: 50rem;

  @include bp-mobile() {
    padding: 2rem 4rem;
  }

  &__banner-timer {
    color: map-get($grayPalette, 'color_ff');
    font-size: 14rem;
    line-height: 22rem;
    font-weight: 600;
  }

  &-timer {
    span {
      min-width: 19rem;
    }
  }
}
</style>
